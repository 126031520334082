<template>
  <div>
    <span
      class="link mb-2"
      @click="back"
    > Назад </span>
    <b-row
      v-if="itemInfo"
      class="mt-2 align-items-center"
    >
      <b-col cols="3">
        <b-overlay :show="loadingBtn">
          <b-button
            variant="primary"
            class="ml-2 text-uppercase"
            @click="checkAttachStatus"
          >
            <feather-icon icon="RefreshCwIcon" />
            <span class="align-middle"> Tekshirish</span>
          </b-button>
        </b-overlay>
      </b-col>
      <b-col cols="9">
        <div class="d-flex justify-content-end align-items-center mt-1">
          <div class="ml-2">
            <p class="ml-2 text-uppercase">
              <b>Status: </b>
              <span
                class="badge badge-info w-100"
                :class="{ 'badge-danger': (itemInfo.status == 'blocked') || (itemInfo.status == 'error') }"
              >
                <feather-icon
                  v-if="itemInfo.status == 'blocked'"
                  icon="LockIcon"
                />
                {{ itemInfo.status }}
              </span>
            </p>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-overlay :show="loading">

      <b-row
        v-if="itemInfo"
        class="mt-2"
      >
        <b-col cols="12">
          <b-card class="earnings-card">
            <div class="d-flex justify-content-between align-middle">
              <h3 class="mb-2">
                Biriktirilgan shartnoma ma'lumotlari
              </h3>
            </div>
            <div
              v-if="
                itemInfo &&
                  itemInfo.contract_id &&
                  itemInfo.contract_product_id &&
                  itemInfo.contract
              "
            >
              <p v-if="itemInfo.contract.client">
                <b>Biriktirilgan mijoz</b>:
                <span
                  class="link"
                  @click="
                    $router.push({
                      name: 'payment-show',
                      params: { id: itemInfo.contract.id },
                    })
                  "
                >
                  {{
                    [
                      itemInfo.contract.client.lastname,
                      itemInfo.contract.client.firstname,
                      itemInfo.contract.client.middlename,
                    ].join(" ")
                  }}
                  ({{
                    itemInfo.contract.client.doc_series +
                      itemInfo.contract.client.doc_number +
                      " / " +
                      itemInfo.contract.client.pin
                  }})
                </span>
              </p>
              <p>
                <b>Mijoz telefoni</b>: +998{{ itemInfo.contract.client.phone }};
                Yaqin qarindoshlari raqamlari: +998{{
                  itemInfo.contract.near_phone1
                }}, +998{{ itemInfo.contract.near_phone2 }}
              </p>
              <p v-if="itemInfo.contract">
                <b>Shartnoma summasi</b>:
                {{ itemInfo.contract.credit_amount | formatPrice }} so'm
              </p>
              <p v-if="itemInfo.contract_product">
                <b>Sotib olingan mahsulot</b>:
                {{ itemInfo.contract_product.category.name_uz }}({{
                  itemInfo.contract_product.barcode
                }})
              </p>
              <p v-if="itemInfo.contract.contract_pdf_url">
                <b>Shartnoma</b>:
                <a
                  :href="storageUrlG + itemInfo.contract.contract_pdf_url"
                  target="_blank"
                  class="link"
                >
                  <feather-icon icon="PaperclipIcon" />Shartnoma</a>
              </p>
              <p>
                <b>Yaratilgan sana</b>:
                {{ dateDotFormat(itemInfo.contract.created_at) }}
              </p>
              <p>
                <b>IMEI 1</b>:
                {{ itemInfo.imei_1 ? itemInfo.imei_1 : 'Mavjud emas' }}
              </p>
              <p>
                <b>IMEI 2</b>:
                {{ itemInfo.imei_2 ? itemInfo.imei_2 : 'Mavjud emas' }}
              </p>
            </div>
            <div v-else>
              <span class="text-muted">Malumot topilmadi</span>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row
        v-if="itemInfo"
        class="mt-2"
      >
        <b-col cols="6">
          <b-card class="earnings-card">
            <h3>Xabarlar</h3>
            <b-button
              v-if="itemInfo.status == 'reserved'"
              variant="outline-info"
              class="btn-tour-skip w-100"
            >
              <span class="align-middle">
                {{ "Ulanish jarayonida" }}
              </span>
            </b-button>
            <b-button
              v-else
              variant="outline-success"
              class="btn-tour-skip"
              @click="openModal('signal')"
            >
              <span class="align-middle">
                <feather-icon icon="PlusIcon" />
                Xabar qo'shish</span>
            </b-button>
            <hr>
            <div v-if="itemInfo && itemInfo.messages && itemInfo.messages.length">
              <div
                v-for="(item, index) in itemInfo.messages"
                :key="index"
                class="info-block"
              >
                <div>
                  <p class="d-flex justify-content-between">
                    <b>{{ item.title }}</b> {{ formatDateYmd(item.created_at) }}
                  </p>
                  <p>
                    <b>Xabar</b>:
                    {{ item.text }}
                    <span v-if="item.created_user">({{ item.created_user.name }})</span>
                  </p>
                </div>
              </div>
            </div>
            <div v-else>
              Xabar yuborilmagan
            </div>
          </b-card>
        </b-col>
        <!-- BLOCKS -->
        <b-col cols="6">
          <b-card class="earnings-card">
            <h3>Bloklashlar</h3>
            <b-button
              v-if="itemInfo.status == 'blocked'"
              v-can="'imei_devices.block'"
              variant="info"
              class="btn-tour-skip w-100"
              @click="openModal('unblock', 1)"
            >
              <span class="align-middle">
                <feather-icon icon="UnlockIcon" />
                {{ "Blokdan chiqarish" }}
              </span>
            </b-button>
            <b-button
              v-else-if="itemInfo.status == 'activated'"
              v-can="'imei_devices.unblock'"
              variant="danger"
              class="btn-tour-skip w-100"
              @click="openModal('block', 1)"
            >
              <span class="align-middle">
                <feather-icon icon="LockIcon" />
                {{ "Bloklash" }}
              </span>
            </b-button>
            <b-button
              v-else
              variant="outline-info"
              class="btn-tour-skip w-100"
            >
              <span class="align-middle">
                {{ "Ulanish jarayonida" }}
              </span>
            </b-button>
            <hr>
            <div v-if="itemInfo && itemInfo.blocks && itemInfo.blocks.length">
              <div
                v-for="(item, index) in itemInfo.blocks.filter(e => e.which_imei == 1)"
                :key="index"
                class="danger-block"
              >
                <div class="d-flex justify-content-between mb-2">
                  <b-button
                    v-b-tooltip.hover.html="item.search_response_data"
                    :variant="item.method == 'block' ? 'outline-danger' : 'outline-success'"
                    class="btn-tour-skip text-uppercase"
                  >
                    <span class="align-middle"> {{ item.method }}</span>
                  </b-button>
                  <!--  -->
                  <div
                    v-if="item.status == 'waiting'"
                    class="d-flex"
                  >
                    <b-button
                      :variant="item.status == 'waiting' ? 'outline-info' : 'outline-success'"
                      class="ml-2 btn-tour-skip text-uppercase"
                    > {{ item.status }}
                    </b-button>

                    <b-button
                      variant="primary"
                      class="btn-tour-skip text-uppercase"
                      @click="checkBlock(item.id)"
                    >
                      Tekshirish
                    </b-button>
                  </div>
                </div>
                <p><b>Status</b>: {{ item.status }}</p>
                <p>Sana: {{ formatDateYmd(item.created_at) }}</p>
                <div>
                  Info:
                  <i v-if="item.response_data">{{
                    item.response_data.result
                      ? item.response_data.result
                      : item.response_data
                  }}</i>
                </div>
              </div>
            </div>
            <div v-else>
              Hali bloklanmagan
            </div>
          </b-card>
        </b-col>

      </b-row>
    </b-overlay>
    <b-modal
      v-model="signalModal"
      size="md"
      centered
      hide-footer
      :title="modalTitle"
      no-close-on-backdrop
      @close="signalModal = false"
      @cancel="signalModal = false"
    >

      <b-form
        class="auth-login-form mt-2"
        @submit.prevent="addAction"
      >
        <b-form-group
          label="Bildirishnoma mavzusi"
          label-for="tag"
        >
          <b-form-input
            id="tag"
            v-model="title_alert"
            name="tag"
          />
        </b-form-group>

        <b-form-group
          label="Izoh"
          label-for="tag"
        >
          <b-form-textarea
            id="body"
            v-model="comment"
            class="form-control"
            placeholder="Комментарий"
            rows="3"
          />
        </b-form-group>
      </b-form>
      <b-row>
        <b-overlay :show="loadingBtn">
          <b-button
            variant="primary"
            class="float-right"
            size="small"
            @click="addAction"
          >
            <feather-icon
              v-if="clickFor != 'detach'"
              :icon="clickFor == 'signal' ? 'SendIcon' : 'LockIcon'"
            />
            {{
              clickFor == "signal"
                ? "Yuborish"
                : clickFor == "detach"
                  ? "Uzish"
                  : knoxInfo.status == "approved"
                    ? "Bloklash"
                    : "Blokdan chiqarish"
            }}
          </b-button>
        </b-overlay>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { showToast } from '@/utils/toast'

export default {
  components: {
  },
  data() {
    return {
      itemInfo: null,
      comment: '',
      title_alert: '',
      contactPhone: null,
      signalModal: false,
      clickFor: 'signal',
      showPassword: false,
      loadingBtn: false,
      loading: false,
      which_imei: 1,
      fields: [
        {
          key: 'number',
          label: '№',
        },
        {
          key: 'date',
          label: 'Дата',
        },
        {
          key: 'amount',
          label: 'Сумма',
        },
      ],
    }
  },
  computed: {
    modalTitle() {
      switch (this.clickFor) {
        case 'signal':
          return 'Xabar yuborish'

        default:
          return (this.itemInfo && this.itemInfo.status == 'unblocked') ? 'Bloklash' : 'Blokdan chiqarish'
      }
    },
  },
  mounted() {
    this.getAction()
  },
  methods: {
    ...mapActions({
      getItem: 'sevenTech/show',
      addBlock: 'sevenTech/addBlock',
      pushAlert: 'sevenTech/push',
      unblock: 'sevenTech/unBlock',
      check: 'sevenTech/check',
      getInfo: 'sevenTech/getInfo',
    }),
    checkAttachStatus() {
      this.loadingBtn = true
      const infoData = {
        seven_tech_device_id: Number(this.$route.params.id),
      }
      console.log(infoData, this.$route.params)

      this.getInfo(infoData)
        .then(res => {
          // showToast('success', this.$t('Tasdiqlandi'))
          this.getAction()
        })
        .finally(() => {
          this.loadingBtn = false
        })
    },
    async checkBlock(id) {
      this.loadingBtn = true
      await this.check({
        imei_device_block_id: id,
      })
        .then(res => {
          // showToast('success', this.$t('Tasdiqlandi'))
          this.getAction()
        })
        .finally(() => {
          this.loadingBtn = false
        })
    },
    getAction() {
      this.loading = true
      this.getItem({ id: this.$route.params.id, relations: 'contract.client|messages|contractProduct|contractProduct.category' }).then(res => {
        this.itemInfo = res.data
      }).finally(() => {
        this.loading = false
      })
    },
    addAction() {
      this.loadingBtn = true
      this.method()
        .then(res => {
          this.signalModal = false
          showToast('success', this.$t('Muvaffaqiyatli amalga oshirildi'))
          this.getAction()
        })
        .finally(() => {
          this.loadingBtn = false
        })
    },
    openModal(type, which = 1) {
      this.which_imei = which
      this.clickFor = type
      if (type == 'signal') {
        this.signalModal = true
      } else {
        this.$bvModal
          .msgBoxConfirm(type == 'block' ? 'Haqiqatdan ham qurilmani bloklamoqchimisiz' : 'Haqiqatdan ham qurilmani blokdan chiqarmoqchimisiz?', {
            title: this.$t('Подтвердите'),
            size: 'sm',
            variant: 'warning',
            okVariant: 'primary',
            okTitle: this.$t('Ha'),
            cancelTitle: this.$t('Yo`q'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              this.addAction()
                .then(res => {
                  showToast('success', this.$t('Bloklashga yuborildi'))
                  this.getItems()
                })
                // .catch(() => {
                //   showToast('success', this.$t('Успешно удалено'))
                // })
            }
          })
      }
    },
    method() {
      if (this.clickFor == 'signal') {
        return this.pushAlert({
          seven_tech_device_id: this.$route.params.id,
          text: this.comment,
          title: this.title_alert,
        })
      }
      if (this.itemInfo.status == 'blocked') {
        return this.unblock({
          seven_tech_device_id: this.$route.params.id,
        })
      }
      return this.addBlock({
        seven_tech_device_id: this.$route.params.id,
      })
    },
  },
}
</script>

<style></style>
